<template>
  <button class="btn align-bottom" @click="onClick">
    <img :src="icon" alt="icon open map action" />
    {{ text }}
  </button>
</template>

<script>
import doubleArrowIcon from "@/assets/images/double-arrow.svg";

export default {
  name: "EditButton",
  data() {
    return {
      icon: doubleArrowIcon,
    };
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function,
      require: true,
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  background-color: $background-button-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 0.8em;
  }
}
</style>
