<template>
  <b-container class="m-0 p-0">
    <!-- Picture and button -->
    <div class="container-activity-path">
      <h4 class="subtitle-form overload-substitle mb-2">Parcours</h4>
      <div @click="onClick" class="clickable">
        <img v-if="currentActivity.areaImage" :src="currentActivity.areaImage" alt="map activity path" />
        <img v-else src="@/assets/images/map.png" alt="map activity path" />
        <doubleArrowButton
          :on-click="() => {}">
        </doubleArrowButton>
      </div>
    </div>
    <modalActivityPath ref="modalPath"></modalActivityPath>
  </b-container>
</template>

<script>
import mapImg from "@/assets/images/map.png";
import doubleArrowButton from "@/components/commons/DoubleArrowButton.vue";
import modalActivityPath from "@/components/activity/modals/ActivityPath.vue";
import { mapGetters } from "vuex";

export default {
  name: "ActivityPath",
  data() {
    return {
      map: mapImg,
    };
  },
  methods: {
    openModal() {
      this.$refs.modalPath.displayPathActivity();
      // console.log("open modal path activity");
    },
    onClick() {
      if(this.currentActivity.cropField_toBeConfirmed)
        this.goToCropfield(this.currentActivity.cropField[0], this.currentActivity.id);
      else
        this.openModal();
    },

    /**
     * @description redirige l'utilisateur vers la page permettant de confirmer le contour de la parcelle
     */
    goToCropfield(idCropfield, idActivity) {
      this.$router.push({ 
        name: "cropfield.map.details", 
        params: { cropfield_id: idCropfield, mode: "outlineMode", activity_id: idActivity, fromActivityView: true} 
      });
    },
  },
  components: {
    doubleArrowButton,
    modalActivityPath,
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
    }),
  },
};
</script>

<style lang="scss" scoped>
/* Container needed to position the button. Adjust the width as needed */
.container-activity-path {
  position: relative;
}

/* Make the image responsive */
.container-activity-path img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 350px;
  border-radius: 4px;
}

/* Style the button and place it at the left bottom of the container/image */
.container-activity-path .btn {
  position: absolute;
  top: 4.5em;
  left: 22.5em;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.container-activity-path .btn:hover {
  background-color: $background-text-color;
}

.clickable {
  cursor: pointer;
}
</style>
